import {useSettings} from '@wix/tpa-settings/react'
import React from 'react'
import settingsParams from '../../../settingsParams'
import {CardList} from './card-list'
import {FeaturedCard} from './featured-card'

export const Cards = () => {
  const {get} = useSettings()
  const featuredEvent = get(settingsParams.featuredEvent)

  return featuredEvent ? <FeaturedCard /> : <CardList />
}
